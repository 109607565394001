.pdf-viewer-container {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 10px;
  width: 100%;
  }
  
  .no-print {
    cursor: none; 
    pointer-events: none; 
  }
  
  .pdf-viewer-container embed {
    border: 1px solid #ccc;
    height: 700px;
    margin-top: 40px;
    transform: scale(1.0);
    width: 1000px;
    }
  
  @media (max-width: 768px) {
    .pdf-viewer-container embed {
      height: 80%;
      width: 80%;
    }
  }
  
  @media (max-width: 480px) {
    .pdf-viewer-container embed {
      height: 100%;
      width: 100%;
    }
  }
  
.fullscreen-gallery {
    background-color: rgba(0, 0, 0, 0.9);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 60;
}

.fullscreen-gallery .image-gallery {
    height: 100%;
}

.fullscreen-gallery .image-gallery-slide img {
    max-height: 100%;
    object-fit: contain;
}

.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
    height: 60px;
}
.image-gallery-left-nav .image-gallery-svg, .image-gallery-left-nav .image-gallery-svg {
    height: 60px;
}
.image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: calc(100vh - 200px);
}
.gm-style-iw-ch{
    padding-top: 0 !important;
} 
.gm-ui-hover-effect{
    align-items: center !important;
    display: flex !important;
    height: fit-content !important;
    justify-content: center !important;
    padding: 5px !important;
    width: fit-content !important;

    > span{
        height: 14px !important;
        margin: 0 !important;
        width: 14px !important;

    }
}
  
.logo-container .ripple-effect {
    animation: ripple 4s infinite; /* Increase animation duration */
}

@keyframes ripple {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.8); /* Increase transparency */
    }
    70% {
        box-shadow: 0 0 0 20px rgba(255, 0, 0, 0); /* Increase the size of the circle */
    }
    100% {
        box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
    }
}
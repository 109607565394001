@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 1s ease-in-out;
}

.dots-container {
  display: flex;
  justify-content: start;
  margin-bottom: 16px;
}

.dot {
  width: 30px;
  height: 4px;
  background-color: #ccc;
  margin: 0 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dot.active {
  background-color: #333;
}
